@import "src/styles/themes";

.content {
    padding-left: 12px;
    padding-right: 12px;
    @media screen and (min-width: $tabletBreakpointW) {
        padding-left: 24px;
        padding-right: 24px;
    }
    .contentDivider {
        margin: 3px 0;
        height: 1px;
        background-color: $divider-background-color-light;
        border: none;
    }
}

.content.dark {
    .contentDivider  {
        background-color: $divider-background-color-dark;
    }
}

.loadFailure {
    padding-top: 20px;
    text-align: center;
}

.spacer {
    display: flex;
    flex-grow: 1;
}
