@import "src/styles/themes";

.detailsLinkContainer {
    flex: 0 0;
    padding: 12px;
    @media screen and (min-width: $tabletBreakpointW) {
        padding-left: 24px;
        padding-right: 24px;
    }
    background-color: #E2E2E2;
    border-top: solid $divider-background-color-light 1px;
    padding-bottom: max(12px, env(safe-area-inset-bottom));
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);

    .detailsLinkButton {
        color: white;
        background: black;
        border: none;
        padding: 12px 0;
        text-align: center;
        width: 100%;
    }
}

.dark.detailsLinkContainer {
    border-top: solid $divider-background-color-dark 1px;
    background-color: #2D2D2D;
    .detailsLinkButton {
        color: #1a1a1a;
        background: white;
    }
}
