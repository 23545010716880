@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/global";
 
.reKindleButton {
    color: $button-text-color-cta;
    background: $button-background-color-cta;
    border: none;
    padding: 10px 12px;
    margin: 0;
    text-align: center;
 
    .label {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
