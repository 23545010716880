@import "src/styles/themes";

.itemContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 75vh;
    padding-bottom: max(0px, env(safe-area-inset-bottom));
    background-color: $background-color-light;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .chevronIconContainer {
        transform: scaleY(0.5);
    }

    .chevronIcon {
        width: 10px;
        height: 10px;
        margin: 5px auto 0px auto;
        border-top: 3px solid #626161;
        border-right: 3px solid #626161;
        transform: rotate(135deg);
    }

    .header {
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .main {
        overflow: auto;
        overscroll-behavior: contain;
        padding-left: 12px;
        padding-right: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        height: 100%;
        text-align: center;
    }

    footer.footer {
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }

    .footerButtons {
        display: flex;
        flex-direction: row;
        padding: 12px;
        gap: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        button {
            flex-grow: 1;
            border: none;
            padding: 10px 12px;
            margin: 0;
            text-align: center;
            white-space: nowrap;
            color: $button-text-color-light;
            background-color: $button-background-color-light;
        }
    }
}

.dark.itemContainer {
    background-color: $background-color-dark;
    color: $primary-text-color-dark;
    border: 1px solid #5a5a5a;
    border-bottom: 0;

    .footer {
        button {
            color: $button-text-color-dark;
            background-color: $button-background-color-dark;
        }
    }
}
